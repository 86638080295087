
      if (import.meta.env.PROD) {
        const script = document.createElement('script');
        script.src = `${import.meta.env.VITE_UMAMI_URL}/script.js`;
        script.setAttribute(
          'data-website-id',
          import.meta.env.VITE_UMAMI_WEBSITE_ID
        );
        document.head.appendChild(script);
      }
    